<template>
  <div class="filter-item">
    <button type="button" class="filter-item__header" @click="open = !open">
      <span class="filter-item__title">{{ title }}</span>
      <RemixIconComponent category="System" v-if="open" name="arrow-drop-up-line" />
      <RemixIconComponent category="System" v-else name="arrow-drop-down-line" />
    </button>
    <div class="filter-item__body" v-if="open">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "FilterItemComponent",
  props: {
    title: String,
  },
  data() {
    return {
      open: false,
    };
  },
  components: { RemixIconComponent },
};
</script>

<style lang="stylus">
.filter-item {
  display grid
  grid-gap 8px
  align-items start

  &__header {
    background none
    border none
    padding 0
    display flex
    align-items center
    justify-content space-between
    gap 8px
    cursor pointer

    &:hover {
      color var(--accent)

      .icon {
        border-color var(--accent)

        svg path {
          fill var(--accent)
        }
      }
    }

    .icon {
      width 16px
      height 16px
      border: 1px solid var(--blue-300);
      box-sizing: border-box;
      border-radius: 4px;
      transition border-color var(--transition)

      svg path {
        fill var(--body-color-muted)
        transition var(--transition)
      }
    }
  }

  &__title {
    font-weight: normal;
    //font-size: 0.875em;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align left
    transition color var(--transition)
  }
}
</style>
