<template>
  <div class="catalog-filter">
    <div class="catalog-filter__header">
      <div class="catalog-filter__header-top">
        <span class="catalog-filter__title">Фильтры</span>
        <button type="button" class="btn btn--sm btn--hollow-dark" @click="$emit('close')">
          <span>Закрыть</span>
          <RemixIconComponent category="System" name="close-line" />
        </button>
      </div>
      <InputComponent
        @input="searchInput"
        v-model.trim="form.title"
        icon-position="left"
        type="search"
        placeholder="Я ищу"
      />
    </div>
    <div class="catalog-filter__body-container">
      <div class="catalog-filter__body">
        <FilterItemComponent title="Цена">
          <CatalogRangeSlider @change="priceChange" :min-value="minValue" :max-value="maxValue" />
        </FilterItemComponent>
        <FilterItemComponent v-for="(filter, i) in filters" :key="i" :title="filter.attribute.title">
          <div class="catalog-filter__checkboxes">
            <CheckboxComponent
              v-for="(val, i) in filter.attribute.values"
              :checked="isChecked(val.id, filter.attribute.id)"
              @change="toggleValue(val.id, filter.attribute.id)"
              :key="i"
            >
              {{ val.value }}
            </CheckboxComponent>
          </div>
        </FilterItemComponent>
      </div>
      <button @click="filter" type="button" class="btn btn--md btn--main">
        <FileIconComponent v-if="loading" name="loading" />
        <template v-else>Применить</template>
      </button>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import FilterItemComponent from "views/catalog/components/FilterItemComponent.vue";
import CheckboxComponent from "components/inputs/CheckboxComponent.vue";
import CatalogRangeSlider from "views/catalog/components/CatalogRangeSlider.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";
import FileIconComponent from "components/FileIconComponent.vue";

export default {
  name: "CatalogFiltersComponent",
  props: {
    loading: Boolean,
  },
  data() {
    return {
      inputDebounceTimeout: null,
      // минимальная и максимальная цена товаров
      minValue: 0,
      maxValue: 1000000,
      // мин. и макс. цена выбранная пользователем
      price_from: 0,
      price_to: 1000000,
      form: {
        title: undefined,
        // мин. и макс. цена выбранная пользователем в долларах
        price_from: 0,
        price_to: 1000000,
        filter_attribute: [],
      },
    };
  },
  mounted() {
    let min = this.parseNumber(
      this.$options.filters.price_from_dollar(this.products_min_max_price?.min, this.$store.state),
      false
    );
    let max = this.parseNumber(
      this.$options.filters.price_from_dollar(this.products_min_max_price?.max, this.$store.state),
      true
    );
    this.minValue = this.price_from = min || 0;
    this.maxValue = this.price_to = max || 0;
  },
  computed: {
    products_min_max_price() {
      return this.$store.state.catalog_page.products_min_max_price;
    },
    filters() {
      return this.$store.state.catalog_page?.filter_attributes?.filter((item) => item.attribute);
    },
  },
  methods: {
    priceChange(range) {
      this.price_from = range.min;
      this.price_to = range.max;
      this.form.price_from = this.$options.filters.rubles_to_dollars(range.min, this.$store.state);
      this.form.price_to = this.$options.filters.rubles_to_dollars(range.max, this.$store.state);
    },
    filter() {
      this.$emit("filter", this.form);
      this.$emit("close");
    },
    searchInput() {
      if (this.inputDebounceTimeout) {
        clearTimeout(this.inputDebounceTimeout);
      }
      this.inputDebounceTimeout = setTimeout(() => {
        this.$emit("filter", this.form);
      }, 500);
    },
    isChecked(valId, attrId) {
      let index = this.form.filter_attribute.findIndex((a) => a.attribute === attrId);
      if (index !== -1) {
        return this.form.filter_attribute[index].attribute_value.includes(valId);
      }
      return false;
    },
    toggleValue(valId, attrId) {
      let index = this.form.filter_attribute.findIndex((a) => a.attribute === attrId);
      if (index !== -1) {
        let i = this.form.filter_attribute[index].attribute_value.indexOf(valId);
        if (i !== -1) {
          this.form.filter_attribute[index].attribute_value.splice(i, 1);
          if (!this.form.filter_attribute[index].attribute_value.length) {
            this.form.filter_attribute.splice(index, 1);
          }
        } else {
          this.form.filter_attribute[index].attribute_value.push(valId);
        }
      } else {
        this.form.filter_attribute.push({
          attribute: attrId,
          attribute_value: [valId],
        });
      }
    },
    parseNumber(str, isMax) {
      let num = parseFloat(str.replace(/\D+/g, ""));
      if (isMax) {
        num = Math.ceil(num);
      } else {
        num = Math.floor(num);
      }
      return num;
    },
  },
  components: {
    FileIconComponent,
    RemixIconComponent,
    CatalogRangeSlider,
    CheckboxComponent,
    FilterItemComponent,
    InputComponent,
  },
};
</script>

<style lang="stylus">
.catalog-filter {
  display grid
  align-items start
  grid-gap 16px
  background: var(--white);
  border-radius: var(--main_radius);
  padding 16px
  //position sticky
  //top 0
  //max-height 100vh
  //overflow-y auto

  &__header {
    border-bottom: 1px solid var(--blue-500);
    display grid
    grid-gap 8px
    padding-bottom 16px
  }

  &__header-top {
    display flex
    align-items center
    justify-content space-between

    .btn {
      +above(861px) {
        display none
      }
    }
  }

  &__title {
    font-weight: normal;
    font-size: 1.125em;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: var(--body-color);
  }

  &__body {
    display flex
    flex-direction column
    grid-gap 16px

    &-container {
      display grid
      grid-gap 16px

      .btn {
        justify-content center
      }
    }
  }

  &__checkboxes {
    display grid
    max-height 290px
    overflow-y auto

    .check {
      color var(--body-color-muted)
      padding 8px 0
      transition var(--transition)

      &:hover {
        color var(--accent)

        .check__state {
          border-color var(--accent)
        }
      }
    }
  }

  &__colors {
    display flex
    gap 12px
    flex-wrap wrap
    align-items flex-start

    li {
      display inline-flex
    }

    label {
      cursor pointer
      width 24px
      height 24px
      flex-shrink 0
      border-radius 100%
      outline 2px solid transparent
      transition outline-color var(--transition)

      &.active {
        outline-color var(--accent)
      }
    }
  }
}
</style>
