<template>
  <div class="catalog-range-slider">
    <MultiRangeSlider
      :min="minValue"
      :max="maxValue"
      :step="10"
      :minValue="parseInt(min)"
      :maxValue="parseInt(max)"
      ref="range-slider"
      @input="handleRangeSliderUpdate"
    />
    <div class="catalog-range-slider__inputs">
      <InputComponent
        :min="minValue"
        :max="parseInt(max)"
        v-model="min"
        type="number"
        :placeholder="minValue"
        @blur="handleMinBlur"
      />
      <InputComponent
        v-model="max"
        :min="parseInt(min)"
        :max="maxValue"
        type="number"
        @blur="handleMaxBlur"
        :placeholder="maxValue"
      />
    </div>
  </div>
</template>

<script>
import MultiRangeSlider from "components/inputs/MultiRangeSliderComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";

export default {
  name: "CatalogRangeSlider",
  props: {
    minValue: Number,
    maxValue: Number,
  },
  data() {
    return {
      min: undefined,
      max: undefined,
    };
  },
  created() {
    this.min = this.minValue;
    this.max = this.maxValue;
  },
  watch: {
    min() {
      this.$emit("change", { min: this.min, max: this.max });
    },
    max() {
      this.$emit("change", { min: this.min, max: this.max });
    },
  },
  methods: {
    handleMinBlur() {
      if (this.min < this.minValue) {
        this.min = this.minValue;
      } else if (this.min > this.max) {
        this.min = this.max;
      }
    },
    handleMaxBlur() {
      if (this.max < this.min) {
        this.max = this.min;
      } else if (this.max > this.maxValue) {
        this.max = this.maxValue;
      }
    },
    handleRangeSliderUpdate(e) {
      this.min = e.minValue;
      this.max = e.maxValue;
    },
  },
  components: { InputComponent, MultiRangeSlider },
};
</script>

<style lang="stylus">
.catalog-range-slider {
  display grid
  grid-gap 20px
  align-items start
  margin-top 12px

  .range-slider {
    padding: 0 10px;
  }

  &__inputs {
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 12px
  }
}
</style>
