<template>
  <CatalogFiltersComponent
    @filter="filter"
    :loading="filterLoading"
    v-if="showFilter"
    @close="showFilter = false"
  />
  <div class="catalog-page page" v-else>
    <BreadcrumbsComponent @back.prevent :links="links" />
    <div class="catalog-page__title-container">
      <h1 class="catalog-page__title">{{ title || "Каталог товаров" }}</h1>
    </div>
    <SliderComponent
      v-if="category"
      class="catalog-page__slider"
      :items="category.siblings"
      hideArrows
      v-slot="{ item }"
      :slider-options="sliderOptions"
    >
      <router-link
        :to="{ name: 'catalog', params: { id: item.id }, query: { page_title: item.title } }"
        class="catalog-page__category"
        :class="{ 'catalog-page__category--active': parseInt($route.params.id) === item.id }"
      >
        <ImgComponent :height="300" :width="300" :head_img="item.head_img" />
        <span>{{ item.title }}</span>
      </router-link>
    </SliderComponent>
    <button
      type="button"
      @click="toggleFilter"
      class="catalog-page__mobile catalog-page__filter-btn btn btn--lg btn--main-inv"
    >
      <RemixIconComponent category="System" name="filter-2-line" />
      <span>Фильтры</span>
    </button>
    <div class="catalog-page__container">
      <div ref="productsList" class="catalog-page__body">
        <CatalogFiltersComponent @filter="filter" :loading="filterLoading" />
        <div class="catalog-page__content">
          <SelectComponent
            v-model="activeSort"
            :clearable="false"
            :searchable="false"
            :options="sortTypes"
            label-name="title"
            title="Сортировать по"
            @input="changeSort"
          />
          <div class="sorts">
            <span class="sorts__title">Сортировать по: </span>
            <button
              class="sorts__item"
              type="button"
              v-for="(sort, i) in sortTypes"
              :key="i"
              @click="changeSort(sort)"
              :class="{
                'sorts__item--active': JSON.stringify(activeSort) === JSON.stringify(sort),
              }"
            >
              {{ sort.title }}
            </button>
          </div>
          <div
            :style="{ opacity: filterLoading || paginateLoading ? 0.7 : 1 }"
            v-if="showProducts"
            class="catalog-page__items"
          >
            <ProductCardComponent :data="item" v-for="(item, i) in products.data" :key="i" small />
          </div>
          <span v-else-if="formData">По данному запросу ничего не найдено</span>
          <span v-else>В данной категории товары отсутвуют</span>
          <PaginationComponent
            v-if="showPagination"
            :page="page"
            :total="Math.ceil(products.paginatorInfo.total / first)"
            @change="paginate"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import SliderComponent from "components/SliderComponent.vue";
import CatalogFiltersComponent from "views/catalog/components/CatalogFiltersComponent.vue";
import ProductCardComponent from "components/product/ProductCardComponent.vue";
import PaginationComponent from "components/Pagination.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";
import SelectComponent from "components/inputs/select/index.vue";
import CATALOG_PAGE from "@/graphql/pages/CatalogPage.graphql";
import CATALOG_PAGE_NO_CATEGORY from "@/graphql/pages/CatalogPageNoCategory.graphql";

let _query = {};
let sortTypes = [
  {
    ordering: {
      orderColumn: "in_popular",
      orderBy: "desc",
    },
    title: "Популярности",
  },
  {
    ordering: {
      orderColumn: "price",
      orderBy: "asc",
    },
    title: "Возрастанию цены",
  },
  {
    ordering: {
      orderColumn: "price",
      orderBy: "desc",
    },
    title: "Убыванию цены",
  },
  {
    ordering: {
      orderColumn: "updated_at",
      orderBy: "desc",
    },
    title: "Обновлению",
  },
];

// переделать численные параметры
function parseParams(query) {
  Object.keys(query).forEach((key) => {
    try {
      query[key] = JSON.parse(query[key]);
    } catch (e) {
      return null;
    }
  });
  return query;
}

export default {
  name: "CatalogPage",
  async asyncData({ apollo, store, route }) {
    _query = parseParams(route.query);
    _query.page_title = undefined;
    await apollo.defaultClient
      .query({
        query: route.params.id ? CATALOG_PAGE : CATALOG_PAGE_NO_CATEGORY,
        variables: {
          parent: true,
          category_id: route.params.id ? parseInt(route.params.id) : undefined,
          first: store.state.isMobile ? 10 : 20,
          ordering: [sortTypes[0].ordering],
          ..._query,
        },
      })
      .then(({ data }) => {
        store.state.catalog_page.products = data.products_paginate;
        store.state.catalog_page.category = data.category;
        store.state.catalog_page.filter_attributes = data.filter_attributes;
        store.state.catalog_page.products_min_max_price = data.products_min_max_price;
        store.state.categories = data.categories;
        store.state.pages = data.pages;
        store.state.marketplace = data.marketplace;
        if (data.exchange_rates) store.state.exchange_rates = data.exchange_rates;
      });
  },
  data() {
    return {
      title: null,
      page: 1,
      first: this.$store.state.isMobile ? 10 : 20,
      query: _query,
      showFilter: false,
      activeSort: sortTypes[0],
      sortTypes: sortTypes,
      filterLoading: false,
      paginateLoading: false,
      formData: null,
      params: {},
      sliderOptions: {
        slidesPerView: 2.1,
        spaceBetween: 22,
        pagination: false,
        clickable: false,
        breakpoints: {
          360: {
            slidesPerView: 2.5,
          },
          540: {
            slidesPerView: 3.5,
          },
          670: {
            slidesPerView: 4.5,
          },
          800: {
            slidesPerView: 5.5,
          },
          860: {
            slidesPerView: 6.5,
          },
          1000: {
            slidesPerView: 6.5,
          },
          1150: {
            slidesPerView: 7.5,
          },
          1240: {
            slidesPerView: 8,
          },
        },
      },
    };
  },
  beforeMount() {
    this.params = this.parseParams(Object.fromEntries(new URLSearchParams(window.location.search)));
    this.title = this.params.page_title;
    this.params.page_title = undefined;
  },
  computed: {
    links() {
      if (this.category) {
        return [{ title: "Каталог товаров", route: { name: "catalog" } }, { title: this.category.title }];
      }
      return [{ title: "Каталог товаров" }];
    },
    category() {
      return this.$store.state.catalog_page.category;
    },
    products() {
      return this.$store.state.catalog_page.products;
    },
    showProducts() {
      return this.products && this.products.data && this.products.data.length;
    },
    showPagination() {
      return this.showProducts && Math.ceil(this.products.paginatorInfo.total / this.first) > 1;
    },
  },
  methods: {
    toggleFilter() {
      window.scrollTo(0, 0);
      this.showFilter = !this.showFilter;
    },
    async changeSort(sort) {
      this.activeSort = sort;
      this.page = 1;
      this.paginateLoading = true;
      await this.load();
    },
    async paginate(page) {
      if (!this.paginateLoading) {
        this.page = page;
        this.paginateLoading = true;
        await this.load();
        this.scrollUp();
      }
    },
    async filter(form) {
      this.page = 1;
      this.formData = form;
      this.filterLoading = true;
      await this.load();
      this.scrollUp();
    },
    async load() {
      await this.$apollo.provider.defaultClient
        .query({
          query: this.category ? CATALOG_PAGE : CATALOG_PAGE_NO_CATEGORY,
          variables: {
            ...this.formData,
            category_id: this.category ? this.category.id : undefined,
            ordering: [this.activeSort.ordering],
            page: this.page,
            first: this.first,
            parent: !(
              this.formData &&
              this.formData.filter_attribute &&
              this.formData.filter_attribute.length
            ),
            ...this.params,
          },
        })
        .then(({ data }) => {
          this.filterLoading = false;
          this.paginateLoading = false;
          this.$store.state.catalog_page.products = data.products_paginate;
          this.$store.state.catalog_page.category = data.category;
          this.$store.state.categories = data.categories;
        })
        .catch(() => {
          this.filterLoading = false;
          this.paginateLoading = false;
        });
    },
    scrollUp() {
      if (window.scrollY > this.$refs.productsList.offsetTop) {
        window.scrollTo({ top: this.$refs.productsList.offsetTop });
      }
    },
    parseParams,
  },
  components: {
    SelectComponent,
    RemixIconComponent,
    PaginationComponent,
    ProductCardComponent,
    CatalogFiltersComponent,
    SliderComponent,
    BreadcrumbsComponent,
  },
  metaInfo: {
    title: "Каталог товаров",
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/sorts.styl"
.catalog-page {
  width 100%
  display grid
  grid-gap 24px
  padding 16px 0

  &__mobile {
    +above(861px) {
      display none
    }
  }

  &__filter-btn {
    justify-content center
    margin 0 15px
  }

  &__body {
    display grid
    grid-template-columns 3fr 9fr
    grid-gap 24px
    max-width var(--main_width)
    width 100%
    align-items start
    +below(1200px) {
      grid-template-columns 4fr 8fr
    }
    +below(860px) {
      grid-template-columns 1fr
      .catalog-filter {
        display none
      }
    }
  }

  &__content {
    display grid
    grid-gap 32px
    +above(861px) {
      .select {
        display none
      }
    }
  }

  &__items {
    display grid
    grid-template-columns repeat(4, 1fr)
    grid-gap 24px
    +below(1200px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(960px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(860px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(700px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(540px) {
      grid-gap 8px
    }
    +below(380px) {
      grid-template-columns 1fr
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
  }

  &__title {
    max-width var(--main_width)
    width 100%
    font-weight: normal;
    font-size: 2.25em;
    line-height: 50px;
    display: flex;
    align-items: center;
    color: var(--body-color);
    margin 0 auto

    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;
    }
  }

  &__category {
    background: var(--white);
    border: 1px solid var(--blue-300);
    box-sizing: border-box;
    border-radius: var(--small_radius);
    padding 10px
    width 100%
    height 100%
    display flex
    flex-direction column
    justify-content center
    align-items center
    gap 16px

    &:hover {
      filter brightness(0.9)
    }

    &--active {
      border-color var(--main)
    }

    img {
      width 80px
      height 80px
      object-fit cover
      object-position center
    }

    span {
      font-weight: normal;
      font-size: 0.875em;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align center
      color: var(--body-color-muted);
    }
  }

  &__slider {
    width: 100%;
    +below(1240px) {
      padding-right 0
    }
  }
}
</style>
